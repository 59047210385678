import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import Instrument from './Instrument';
import { Box, Button, ButtonGroup, Fab, Stack } from '@mui/material';
import { addInstrument as addInstrumentAction, lastId } from '../store/reducers/instruments';
import { generateInstrument, generateMelody, sortMelody } from '../lib/dna';
import { resetMelody, updateMelody } from '../store/reducers/melody';
import { calcMelodyLength } from '../App';
import AddIcon from '@mui/icons-material/Add'

const getFreeChannel = (instruments: Record<number, Instrument>) => {
    const channels = Object.keys(instruments).map((x) => instruments[x as any].channel)

    for (let i = 1; i < 11; i++) {
        if (!channels.includes(i)) {
            return i
        }
    }

    return 0
}

const Instruments: FC = () => {
    const instruments = useSelector((s :RootState) => s.instruments)
    const melody = useSelector((s :RootState) => s.melody)
    const zones = useSelector((s :RootState) => s.zones)

    const dispatch = useDispatch()

    const addInstrument = () => {
        const rand = () => Math.random()
        const newInstrument = generateInstrument(rand, calcMelodyLength(melody.current))
        newInstrument.id = lastId+1
        newInstrument.name = lastId+2+''
        newInstrument.channel = getFreeChannel(instruments)
       
        dispatch(addInstrumentAction(newInstrument))
        dispatch(
            updateMelody(
                [
                    sortMelody(
                        [...melody.current, ...generateMelody({
                            density: rand(),
                            length: calcMelodyLength(melody.current),
                            rand,
                            zones,
                            instrument: newInstrument
                        })]
                    ), true
                ]
            )
        )
    }

    return (
        <div>
            {
                Object.keys(instruments).sort().map(i => {
                    return (
                        <Instrument key={i} instrument={instruments[parseInt(i)]}/>
                    )
                })
            }
            <Box paddingTop={4} display={'flex'} justifyContent={'flex-end'}>
                <Fab color="primary" variant="extended" onClick={addInstrument}>
                    <AddIcon sx={{ mr: 1 }} />
                    Add instrument
                </Fab>
            </Box>
        </div>
    );
};

export default Instruments;
