import { FC, useEffect, useState } from 'react';
import { WebMidi } from 'webmidi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { PlayerConfigState, updateSettings } from '../store/reducers/playerConfig';
import Emitter, { events } from '../lib/eventemitter';
import Select from './Select';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Slider, Stack, Typography } from '@mui/material';

const Config: FC = () => {
    const playerConfig = useSelector((s :RootState) => s.playerConfig)
    const [outputs, setOutputs] = useState(WebMidi.outputs)
    const dispatch = useDispatch()

    function setPlayerConfig<K extends keyof PlayerConfigState> (key: K, value: PlayerConfigState[K]) {
        dispatch(updateSettings({key, value}))
    }

    useEffect(()=>{
        return Emitter.subscribe(events.eventChannelsChanged, () => {
            setOutputs([...WebMidi.outputs])
        })
    }, [])

    return (
        <div>
        <Paper elevation={2}>
        <Stack marginBottom={1} spacing={2} direction='row' padding={2}>
            <FormControlLabel
                checked={playerConfig.metronome}
                onChange={(e) => setPlayerConfig('metronome', (e.target as HTMLInputElement).checked)}
                control={<Checkbox />}
                label="Metronome"
            />
            {/* <FormControlLabel
                checked={playerConfig.instantantQuantizeScale}
                onChange={(e) => setPlayerConfig('instantantQuantizeScale', (e.target as HTMLInputElement).checked)}
                control={<Checkbox />}
                label="Instant quantize scale"
            /> */}
            <Select
                value={playerConfig.drumsOutput}
                label="Metronome output"
                onChange={(x) =>
                    setPlayerConfig('drumsOutput', x)
                }
                options={outputs.map((x, idx) => ({value: idx, name: x.name}))}
            />
         
        </Stack>
        </Paper>
        <Paper elevation={2}>
        <Stack>
            <FormControl style={{padding: 15, position: 'relative'}}>
                
                <Typography id="input-slider" gutterBottom>
                    BPM
                </Typography>
                <Slider
                    aria-label="BPM"
                    value={playerConfig.bpm}
                    onChange={(n) => setPlayerConfig('bpm', parseInt((n.target as HTMLInputElement).value))}
                    valueLabelDisplay="on"
                    valueLabelFormat={(v) => `${v}bpm`}
                    min={30}
                    max={240}
                />
            </FormControl>
        </Stack>   
        </Paper>
        </div>   
    );
};

export default Config;
