import { GlobalEvoParams, RandFunc } from "./evo";
import { Note, NoteType, scales, frames, numToNote } from "./note";

export interface Zone {
    start: number;
    scale: number[];
    root: number;
}

export interface ZoneEvoConfig {
    minDistance: number, // Min time to last zone change before a new change can happen
    addZoneChance: number // Chance that a zone is added when a change can happen
}

export const getZone = (note: Note, zones: Zone[]) => {
    return zones.reduce((acc, curZone) => {
        return curZone.start <= note.position ? curZone : acc
    })
}

export const lastZone = (zones: Zone[]) => {
    return zones[zones.length - 1]
}

export const needsNewZone = (zones: Zone[], lastMelodyPos: number, cfg: GlobalEvoParams) => {
    if (lastMelodyPos - lastZone(zones).start > cfg.minDistance) {
        return true
    }

    return false
}

const diffZones = (zone: Zone, scale: number[], root: number) => {
    const old = zone.scale.map((n) => (n + zone.root) % 12);
    const n = scale.map((n) => (n + root) % 12);

    if (old.length < n.length) {
        return old.reduce((acc, cur) => {
            if (!n.includes(cur)) {
                acc += 1
            }

            return acc
        }, 0)
    }

    return n.reduce((acc, cur) => {
        if (!old.includes(cur)) {
            acc += 1
        }

        return acc
    }, 0)
}


const evoZoneScaleAndRoot = (zone: Zone, maxDistance: number, rand: RandFunc) => {
    const scaleKeys = Object.keys(scales)
    while (true) {
        const sKey = scaleKeys[Math.floor(rand() * scaleKeys.length)] as (keyof typeof scales)
        const newScale = scales[sKey]
        const newRoot = Math.floor(rand() * 11)

        const diff = diffZones(zone, newScale, newRoot)
        if (diff <= maxDistance && diff) {
            return {root: newRoot, scale: newScale}
        }
    }
}

export const genNewZone = (zones: Zone[], lastMelodyPos: number, cfg: GlobalEvoParams, rand: RandFunc) : Zone => {
    const lZone = lastZone(zones)

    let zoneStart = Math.ceil(lastMelodyPos / (NoteType.quarter * frames));
    if (lastMelodyPos % (NoteType.quarter * frames) === 0) {
        zoneStart += 1;
    }

    const {scale, root} = evoZoneScaleAndRoot(lZone, cfg.maxZoneTonalChange, rand)

    return {
        start: zoneStart * frames,
        scale,
        root,
    }
}