export const ccMap = {
    0: 'bankselectcoarse',
    1: 'modulationwheelcoarse',
    2: 'breathcontrollercoarse',
    4: 'footcontrollercoarse',
    5: 'portamentotimecoarse',
    6: 'dataentrycoarse',
    7: 'volumecoarse',
    8: 'balancecoarse',
    10: 'pancoarse',
    11: 'expressioncoarse',
    12: 'effectcontrol1coarse',
    13: 'effectcontrol2coarse',
    18: 'generalpurposeslider3',
    19: 'generalpurposeslider4',
    32: 'bankselectfine',
    33: 'modulationwheelfine',
    34: 'breathcontrollerfine',
    36: 'footcontrollerfine',
    37: 'portamentotimefine',
    38: 'dataentryfine',
    39: 'volumefine',
    40: 'balancefine',
    42: 'panfine',
    43: 'expressionfine',
    44: 'effectcontrol1fine',
    45: 'effectcontrol2fine',
    64: 'holdpedal',
    65: 'portamento',
    66: 'sustenutopedal',
    67: 'softpedal',
    68: 'legatopedal',
    69: 'hold2pedal',
    70: 'soundvariation',
    71: 'resonance',
    72: 'soundreleasetime',
    73: 'soundattacktime',
    74: 'brightness',
    75: 'soundcontrol6',
    76: 'soundcontrol7',
    77: 'soundcontrol8',
    78: 'soundcontrol9',
    79: 'soundcontrol10',
    80: 'generalpurposebutton1',
    81: 'generalpurposebutton2',
    82: 'generalpurposebutton3',
    83: 'generalpurposebutton4',
    91: 'reverblevel',
    92: 'tremololevel',
    93: 'choruslevel',
    94: 'celestelevel',
    95: 'phaserlevel',
    96: 'databuttonincrement',
    97: 'databuttondecrement',
    98: 'nonregisteredparametercoarse',
    99: 'nonregisteredparameterfine',
    100: 'registeredparametercoarse',
    101: 'registeredparameterfine',
    120: 'allsoundoff',
    121: 'resetallcontrollers',
    122: 'localcontrol',
    123: 'allnotesoff',
    124: 'omnimodeoff',
    125: 'omnimodeon',
    126: 'monomodeon',
    127: 'polymodeon'
};
