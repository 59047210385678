import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
    GlobalEvoParams, range
} from '../../lib/evo';
import { NoteType, frames } from '../../lib/note';
import { loadState } from './actions';

export const qSmall = [NoteType.sixteenth * frames, NoteType.eight * frames];
export const qAll = range(0, frames * 2);

export const initialState: GlobalEvoParams = {minDistance: 0, addZoneChance: 0, maxZoneTonalChange: 0}

export const globalEvoParamsSlice = createSlice({
    name: 'globalEvoParams',
    initialState,
    reducers: {
        updateGlobalEvoParams: function <K extends keyof GlobalEvoParams>(
            state: GlobalEvoParams,
            action: PayloadAction<{
                key: K;
                value: GlobalEvoParams[K];
            }>
        ) {
            state[action.payload.key] = action.payload.value

            return state;
        },
        resetGlobalEvoParams: (
            state,
            action: PayloadAction<{params: GlobalEvoParams}>
        ) => {
            return action.payload.params
        }
        // https://redux-toolkit.js.org/api/createSlice#the-extrareducers-builder-callback-notation
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadState, (state, action) => {
                return action.payload.globalEvoParams
            })
    }
});

// Action creators are generated for each case reducer function
export const { updateGlobalEvoParams, resetGlobalEvoParams } = globalEvoParamsSlice.actions;

export default globalEvoParamsSlice.reducer;
