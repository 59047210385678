import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Zone } from '../../lib/zones';
import { loadState } from './actions';

export const initialState: Zone[] = []

export const zonesSlice = createSlice({
    name: 'zones',
    initialState,
    reducers: {
        resetZones: function (
            state,
            action: PayloadAction<Zone[]>
        ) {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadState, (state, action) => {
                return action.payload.zones
            })
    }
});

// Action creators are generated for each case reducer function
export const { resetZones } = zonesSlice.actions;

export const getMelody = ({ zones }: RootState) => {
    return zones;
};

export default zonesSlice.reducer;
