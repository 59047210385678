import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Note, NoteType, calcPos, frames } from '../../lib/note';
import { RootState } from '..';
import { changeInstrumentChannel, changeInstrumentOutput, loadState, removeInstrument } from './actions';

export const initialState: {
    current: Note[];
    iteration: number;
} = {
    current: [],
    iteration: -1
};

export const melodySlice = createSlice({
    name: 'melody',
    initialState,
    reducers: {
        updateMelody: function (
            state,
            action: PayloadAction<[Note[], boolean]>
        ) {
            state.current = action.payload[0];
            action.payload[1] && state.iteration++;
        },
        resetMelody: function (
            state,
            action: PayloadAction<{ melody: Note[]; iteration: number }>
        ) {
            state.current = action.payload.melody;
            state.iteration = action.payload.iteration;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeInstrumentOutput, (state, action) => {
                state.current = state.current.map((n) => {
                    if (n.instrument != action.payload.instrument) {
                        return n;
                    }
                    return { ...n, output: action.payload.output };
                });
                return state;
            })
            .addCase(changeInstrumentChannel, (state, action) => {
                state.current = state.current.map((n) => {
                    if (n.instrument != action.payload.instrument) {
                        return n;
                    }
                    return { ...n, channel: action.payload.channel };
                });
                return state;
            })
            .addCase(removeInstrument, (state, action: PayloadAction<{instrument: number}>) => {
                state.current = state.current.filter((n) => {
                    return n.instrument != action.payload.instrument
                });
                return state;
            })
            .addCase(loadState, (state, action) => {
                return action.payload.melody
            })
    }
});

// Action creators are generated for each case reducer function
export const { updateMelody, resetMelody } = melodySlice.actions;

export const getMelody = ({ melody }: RootState) => {
    return melody.current;
};

export const getCurrentIteration = ({ melody }: RootState) => {
    return melody.iteration;
};

export default melodySlice.reducer;
