import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import { FC } from "react";

interface SeedModalProps {
    seed?: string,
    setNewSeed: (seed: string) => void
}

const SeedModal : FC<SeedModalProps> = ({seed, setNewSeed}) => {
    return (
    <Dialog
    open={!seed}
    onClose={() => {}}
    PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(
                (formData as any).entries()
            );

            setNewSeed(formJson.seed)
        }
    }}
>
    <DialogTitle>Set seed</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Provide DNA for generation of new melody 
        </DialogContentText>
        <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="seed"
            label="DNA"
            type="text"
            fullWidth
            variant="standard"
        />
    </DialogContent>
    <DialogActions>
        <Button type="submit">Inject DNA</Button>
    </DialogActions>
</Dialog>
    )
}

export default SeedModal