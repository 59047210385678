import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, FC, useState } from "react";
import { RootState } from "../store";
import styled from "@emotion/styled";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

interface LoadModalProps {
    setOpen: (open: boolean) => void
    loadState: (state: RootState) => void
}

const LoadModal : FC<LoadModalProps> = ({setOpen, loadState}) => {
    const [file, setFile] = useState<File>()

    const handleFileSelect : ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFile((event.target as HTMLInputElement).files![0])
      }
    return (
    <Dialog
    open={true}
    onClose={() => {}}
    PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            let reader = new FileReader(); // built in API
            // Closure to capture the file information.
            reader.addEventListener('load', (e: ProgressEvent<FileReader> ) => {
                const state = JSON.parse(e.target!.result as string) as RootState
                loadState(state)
                setOpen(false)
            })
            // Read the file as text.
            reader.readAsText( file! );
        }
    }}
>
    <DialogTitle>Load file</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Upload a previously saved file
        </DialogContentText>
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            >
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleFileSelect}/>
        </Button>
    </DialogContent>
    <DialogActions>
        <Button type="submit">Load file</Button>
    </DialogActions>
</Dialog>
    )
}

export default LoadModal