import { Note, frames } from "../note"
import Instrument from "../../components/Instrument"
import { RandFunc, quantizePosition, randMinMax, randFromArray, scaleQuantize } from "../evo"
import { Zone, getZone } from "../zones"

interface GenerateMelodyProps {
    density: number,
    length: number,
    rand: RandFunc,
    zones: Zone[],
    instrument: Instrument
}

const calcDensity = (length: number, n: number) => n / length

export const sortMelody = (notes: Note[]) => notes.sort((a, b) => a.position - b.position)

export const generateMelody = ({density, length, rand, zones, instrument}: GenerateMelodyProps) => {
    const notes : Note[] = []

    while(!notes.length || calcDensity(length, notes.length) <= density) {
        const newNote = {
            position: quantizePosition(Math.floor(rand() * length * frames), instrument.positionChangeValuesAbsolute),
            note: randMinMax([instrument.toneMax, instrument.toneMin],rand),
            length: randFromArray(instrument.durationChangeValuesAbsolute, rand),
            output: instrument.output,
            channel: instrument.channel,
            volume: rand(),
            instrument: instrument.id
        } as Note
        
        const zone = getZone(newNote, zones)

        const q = scaleQuantize(
            newNote.note,
            zone.scale,
            zone.root,
            instrument.doesTonalQuantizeOnPositionChange,
            rand
        )

        if (q != undefined) {
            newNote.note = q
        }

        notes.push(newNote)
    }

    return sortMelody(notes) ;
}