import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { loadState } from './actions';

export interface AppState {
    seed: string;
}

export const initialState: AppState = {
    seed: ''
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateAppSettings: function <K extends keyof AppState>(
        state: AppState,
        action: PayloadAction<{key: K; value: AppState[K]}>,
    ) {
        state[action.payload.key] = action.payload.value;

        return state;
    },
    resetAppSettings: (state, event: PayloadAction<AppState>) => {
      return event.payload
    } 
  },
  extraReducers: (builder) => {
    builder
        .addCase(loadState, (state, action) => {
            return action.payload.app
        })
  }
})

// Action creators are generated for each case reducer function
export const { updateAppSettings, resetAppSettings } = appSlice.actions

export default appSlice.reducer

