import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Note, NoteType, frames } from "../lib/note";
import { WebMidi } from "webmidi";
import { Clock } from "../lib/clock";
import Emitter, { events } from "../lib/eventemitter";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { PlayerConfigState } from "../store/reducers/playerConfig";
import { getMelody } from "../store/reducers/melody";
import { Zone } from "../lib/zones";
import { calcMelodyLength } from "../App";
import Instrument from "./Instrument";
import { CCCurve, CCEvent } from "../store/reducers/instruments";


export interface PlayerProps {
    beforeLoop: () => void;
    zones: Zone[];
    onZoneChange: (frame: Zone | undefined) => void;
    updateCount: (cnt: number) => void
}

export interface PlayerRef {
    play: () => void;
    stop: () => void;
    pauze: () => void;
    outputs: typeof WebMidi.outputs;
    isPlaying: () => boolean;
}

const getZone = (zones: Zone[], pos: number) => {
    return zones.reduce((acc : undefined | Zone, cur) => {
        if (cur.start <= pos) {
            return cur
        }

        return acc
    }, undefined)
}

// (
//     async () => {
//         await WebMidi.enable()

//         WebMidi.inputs.forEach(x => {
//             console.log('inputs!', x.name)
//             x.addListener("controlchange", e => {
            
//                 console.log(e.message.data);
//             })
//         })
//     }
// )()

function calculateNumberBetween(start:number, end:number, percentage: number, curve: CCCurve) {
    if (percentage < 0 || percentage > 1) {
        return null;  // Invalid percentage
    }
    if (start === end) {
        return start;  // If start and end are the same, return either
    }

    // TODO add more curves
    // if (curve == CCCurve.Linear) {
        if (start < end) {
            return Math.round(start + percentage * (end - start));
        } else {
            return Math.round(start - percentage * (start - end));
        }
    // }
}

const calculateCurrentCCValue = (pos: number, e: CCEvent[], length: number, curve: CCCurve): number | null => {
    
    // If curve none is selected dotn try to interpolate the value but
    // only return value if current position is a match to a change event
    if (curve == CCCurve.None) {
        const cur = e.filter(x => x.position === pos)[0]
        return cur ? cur.value : null
    }

    const prev = e.reduce((acc, cur) => {
        const diff = pos - cur.position
        const accDiff = pos - acc.position

        if (diff >= 0 && (diff < accDiff || accDiff < 0)) {
            return cur
        }

        return acc
    }, e[e.length -1])

    const next = e.reduce((acc, cur) => {
        const diff = cur.position - pos
        const accDiff = acc.position - pos

        if (diff >= 0 && (diff < accDiff || accDiff < 0)) {
            return cur
        }

        return acc
    }, e[0])

    if (prev.position == next.position) {
        prev.value
    }

    const left = prev.position > pos ? 0 - (pos + length - prev.position) : prev.position
    const right = next.position < pos ? length + next.position : next.position

    if (left == right) {
        return next.value
    }

    const percentage = (pos - left) / (right - left)

    return calculateNumberBetween(prev.value, next.value, percentage, curve)
}

// (function() {
//     console.log(findClosest(30, [{
//         position: 26,
//         value: 0
//     }, {
//         position: 30,
//         value: 0
//     }, {
//         position: 50,
//         value: 0
//     }], 100))
// })()


const sendCC = (position: number, instruments: Record<number, Instrument>, melodyLength: number, webMidi: typeof WebMidi) => {
    Object.keys(instruments).forEach((k) => {
        const instrument = instruments[parseInt(k, 10)]
        let output = webMidi.outputs[instrument.output];
        let channel = output.channels[instrument.channel];

        instrument.cc.forEach((cc, idx) => {
            if (!cc.enabled || !cc.events.length) {
                return
            }

            let value = calculateCurrentCCValue(position, cc.events, melodyLength * frames, cc.curve)
            value !== null && channel.sendControlChange(idx, value)
        })
    })
}


const Player = forwardRef<PlayerRef, PlayerProps>((props, ref) => {
    const playerConfig = useSelector((s: RootState) => s.playerConfig)
    const melody = useSelector(getMelody)
    const instruments = useSelector((s: RootState) => s.instruments)
    const propsref = useRef<PlayerProps>(props)
    const melodyref = useRef<Note[]>(melody)
    const melodyLengthRef = useRef<number>(calcMelodyLength(melody))
    const playerConfigRef = useRef<PlayerConfigState>(playerConfig)
    const instrumentsref = useRef<Record<number, Instrument>>(instruments)
    
    const pos = useRef<number>(0)
    const playing = useRef<boolean>(false)
    const webMidi = useRef<typeof WebMidi>(WebMidi)
    const clock = useRef<typeof Clock>(Clock)
    const ready = useRef(false)
    const curZone = useRef(getZone(props.zones, pos.current))

    useEffect(() => {
        clock.current.setBPM(playerConfig.bpm)
    },[playerConfig.bpm])

    useEffect(() => {
        playerConfigRef.current = playerConfig
    }, [playerConfig])

    useEffect(() => {
        propsref.current = props
    }, [props])

    useEffect(() => {
        melodyref.current = melody
        melodyLengthRef.current = calcMelodyLength(melody)
    }, [melody])

    useEffect(() => {
        instrumentsref.current = instruments
    }, [instruments])

    useImperativeHandle(ref, () => {
        return {
            play: () => {
                playing.current = true
            },
            stop: () => {
                pos.current = 0
                playing.current = false
                props.updateCount(pos.current)
            },
            pauze: () => {
                playing.current = false
            },
            outputs: webMidi.current.outputs,
            isPlaying: () => {
                return playing.current
            }
        }
    }, [ready.current]);

    const processTick = () => {
        const {beforeLoop, updateCount} = propsref.current
        const {metronome, loop, drumsOutput} = playerConfigRef.current
        const melody = melodyref.current
        const melodyLength = melodyLengthRef.current
        
        const instruments = instrumentsref.current
        const loopRange = calcMelodyLength(melody)
        if (!ready.current) {
            return
        }

        if (!playing.current) {
            return
        }
            
        const m = []
        let maxTicks = 0
        for (let idx = 0; idx < melody.length; idx++) {
            const element = melody[idx];
            if (element.position < pos.current) {
                continue
            }

            if (element.position > maxTicks) {
                maxTicks = element.position
            }

            m.push(element)
        }

        const z = getZone(propsref.current.zones, pos.current)

        if (curZone.current != z) {
            curZone.current = z
            propsref.current.onZoneChange(z)
        }
        
        if (pos.current > maxTicks && !loop) {
            stop()
            return
        }

        // Detect start new loop
        if (loop && pos.current / frames >= loopRange) {
            pos.current = 0
            const t0 = performance.now();
            beforeLoop()            
            const t1 = performance.now();
            console.log(t1 - t0, 'milliseconds');
            return
        }


        if (metronome) {
            if (pos.current === 0) {
                webMidi.current.outputs[drumsOutput].channels[1].playNote('A#5', {
                    duration: 200,
                    attack: 1
                });
            }
            if (pos.current % (NoteType.quarter * frames) === 0) {
                webMidi.current.outputs[drumsOutput].channels[1].playNote('C3', {
                    duration: 200,
                    attack: 1
                });
            }
        }

        m.forEach(note => {
            if (note.position == pos.current) {
                //play note
                let instrument = instruments[note.instrument]
                let output = webMidi.current.outputs[instrument.output];
                let channel = output.channels[instrument.channel];
                channel.playNote(note.note, {duration: clock.current.noteDuration(note.length), attack: note.volume || 1});
            }
        })

        if (pos.current % (frames / 4) == 0) {
            sendCC(pos.current, instruments, melodyLength, webMidi.current)
        }

        pos.current++
        setTimeout(() => updateCount(pos.current))
    }

    useEffect(() => {
        (
            async () => {
                await WebMidi.enable()
                ready.current = true
                Emitter.trigger(events.eventChannelsChanged)
            }
        )()
        
        return clock.current.subscribe(processTick)
    }, [])

    return null
})

export default Player