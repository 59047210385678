import { FC, useEffect, useState } from 'react';
import Slider from './Slider';
import { WebMidi } from 'webmidi';
import { useDispatch } from 'react-redux';
import { InstrumentEvoParams, qAll, qSmall, updateInstrumentCC, updateInstrumentParams } from '../store/reducers/instruments';
// import { qAll, qSmall, updateEvoParams } from '../store/reducers/evoParams';
import Emitter, { events } from '../lib/eventemitter';
import { numToNote } from '../lib/note';
import { Instrument } from '../store/reducers/instruments';
import { changeInstrumentChannel, changeInstrumentOutput, removeInstrument } from '../store/reducers/actions';
import { range } from 'lodash';
import { Accordion, AccordionSummary, Typography, AccordionDetails, TextField, FormControl, Slider as MUISlider, Stack, FormControlLabel, Checkbox, ButtonGroup, Button, FormGroup, Switch, Grid, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from './Select';
import { genInstrumentColor } from '../App';
import { ccMap } from '../lib/cc';

interface InstrumentProps {
    instrument: Instrument
}

const Instrument: FC<InstrumentProps> = ({instrument}) => {
    // const instruments = useSelector((s :RootState) => s.instruments)
    // console.log(Object.values(instruments).map(x => ({c: x.channel, o: x.output})))
    const [outputs, setOutputs] = useState(WebMidi.outputs)
    const dispatch = useDispatch()

    function setInstrument<K extends keyof Instrument> (key: K, value: Instrument[K]) {
        dispatch(updateInstrumentParams({instrument: instrument.id, key, value}))
    }

    function setEvoParams<K extends keyof InstrumentEvoParams> (key: K, value: InstrumentEvoParams[K]) {
        dispatch(updateInstrumentParams({instrument: instrument.id, key, value}))
    }

    useEffect(()=>{
        return Emitter.subscribe(events.eventChannelsChanged, () => {
            setOutputs([...WebMidi.outputs])
        })
    }, [])

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <div onClick={e => e.stopPropagation()}>
            <Stack direction="row" gap={1}>
                    <div className='c-instrument-color' style={{backgroundColor: genInstrumentColor(instrument.id)}}></div>
                    <TextField label='Instrument name' size='small' value={instrument.name} onChange={(x) =>
                        setInstrument('name', x.target.value)
                    }/>
                    {/* <FormControlLabel
                        checked={instrument.positionChangeValuesAbsolute != qAll}
                        onChange={(_, checked) => setEvoParams('positionChangeValuesAbsolute', checked ? qSmall : qAll)}
                        control={<Checkbox />}
                        label="quantizeTime"
                    /> */}
                    <Select 
                        label="MIDI Output" 
                        value={instrument.output}  
                        onChange={(v) => dispatch(
                            changeInstrumentOutput({
                                instrument: instrument.id,
                                output: v
                            })
                        )}
                        options={
                            outputs.map((x, idx) => ({value: idx, name: x.name}))
                        }
                    />
                    <Select 
                        label="MIDI Channel" 
                        value={instrument.channel}  
                        onChange={(v) => dispatch(
                            changeInstrumentChannel({
                                instrument: instrument.id,
                                channel: v
                            })
                        )}
                        options={
                            range(1,9).map(n => ({name: 'Channel ' + n})).map((x, idx) => ({value: idx + 1, name: x.name}))
                        }
                    />
                    <Button variant="outlined" color="error" onClick={() => dispatch(removeInstrument({instrument: instrument.id}))}>Remove instrument</Button>
                </Stack>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="column">
                    <Grid container columns={12}>
                    {/* <Slider
                        label="Effective scale percentage"
                        value={Math.round(instrument.effectiveScalePercentage * 100)}
                        setValue={(n) => setEvoParams('effectiveScalePercentage', n / 100)}
                        min={0}
                        max={100}
                        display={(v) => `${v}%`}
                    /> */}
                    <Grid item xs={4}>
                        <Slider
                        label="Duplication change percentage"
                        value={Math.round(instrument.duplicationChange * 100)}
                        setValue={(n) => setEvoParams('duplicationChange',n /100)}
                        display={(v) => `${v}%`}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="Tone change percentage"
                        value={Math.round(instrument.toneChange * 100)}
                        setValue={(n) => setEvoParams('toneChange', n / 100)}
                        display={(v) => `${v}%`}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size='small' fullWidth style={{padding: 5, position: 'relative'}}>
                        
                        <Typography id="input-slider" gutterBottom>
                            Tonal range
                        </Typography>
                        <MUISlider
                            size="small"
                            value={[instrument.toneMin, instrument.toneMax]}
                            onChange={(_, v, activeThumb) => {
                                const [min, max] = v as number[]
                                // instrument.toneMin
                                // instrument.toneMax
                                const minDistance = 2

                                if (activeThumb === 0) {
                                    // setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
                                    
                                    setEvoParams('toneMin', Math.min(min, instrument.toneMax - minDistance))
                                    setEvoParams('toneMax', instrument.toneMax)
                                } else {
                                    setEvoParams('toneMin', instrument.toneMin)
                                    setEvoParams('toneMax', Math.max(max, instrument.toneMin + minDistance))
                                }
                                
                                
                            }}
                            valueLabelDisplay='auto'
                            valueLabelFormat={v => numToNote(v)}
                            min={0}
                            max={127}
                            step={1}
                            disableSwap
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="Position change percentage"
                        value={Math.round(instrument.positionChange * 100)}
                        setValue={(n) => setEvoParams('positionChange', n / 100)}
                        display={(v) => `${v}%`}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="positionChangeSteepness"
                        value={instrument.positionChangeSteepness}
                        setValue={(n) => setEvoParams('positionChangeSteepness', n)}
                        min={0}
                        max={10}
                        display={(v) => `n ^ ${v}`}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="Stretch at density"
                        value={instrument.stretchChange}
                        setValue={(n) => setEvoParams('stretchChange', n)}
                        display={(v) => `${v}`}
                        min={0.5}
                        max={30}
                        
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="Stretch change steepness"
                        value={instrument.stretchChangeSteepness}
                        setValue={(n) => setEvoParams('stretchChangeSteepness', n)}
                        min={0}
                        max={10}
                        display={(v) => `n ^ ${v}`}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="Duration change percentage"
                        value={Math.round(instrument.durationChange * 100)}
                        setValue={(n) => setEvoParams('durationChange', n / 100)}
                        display={(v) => `${v}%`}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="durationChangeSteepness"
                        value={instrument.durationChangeSteepness}
                        setValue={(n) => setEvoParams('durationChangeSteepness', n)}
                        min={0}
                        max={10}
                        display={(v) => `n ^ ${v}`}
                    />
                    </Grid>
                    <Grid item xs={4}>
                        <Slider
                        label="Volume change percentage"
                        value={Math.round(instrument.volumeChange * 100)}
                        setValue={(n) => setEvoParams('volumeChange', n / 100)}
                        display={(v) => `${v}%`}
                    />
                    </Grid>

                    <Grid item xs={4}>
                        <Slider
                        label="Delete change"
                        value={Math.round(instrument.deleteChance * 100)}
                        setValue={(n) => setEvoParams('deleteChance', n / 100)}
                        display={(v) => `${v}%`}
                    />
                    </Grid>
                    </Grid>
                </Stack>
                <Stack>
                    
                    {
                        <Grid container columns={10}>
                        {instrument.cc.map(
                            (cc, idx) => (
                                <Grid key={idx} item xs={1}>
                                    <Tooltip
                                        placement="top"
                                        arrow
                                        title={ccMap[idx as keyof typeof ccMap] || 'nondefault'}
                                        slotProps={{
                                            popper: {
                                              modifiers: [
                                                {
                                                  name: 'offset',
                                                  options: {
                                                    offset: [0, -14],
                                                  },
                                                },
                                              ],
                                            },
                                          }}
                                    >
                                        <FormControlLabel
                                            label={'cc#' + idx}
                                            control={
                                                <Switch
                                                    checked={cc.enabled}
                                                    onChange={e => {
                                                        dispatch(
                                                            updateInstrumentCC({
                                                                instrument: instrument.id,
                                                                cc: idx,
                                                                event: {enabled: e.target.checked, curve: cc.curve}
                                                            })
                                                        )
                                                    }}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                </Grid>
                            )
                            
                        )}
                        </Grid>
                    }
                </Stack>
            </AccordionDetails>
        </Accordion>
        
    );
};

export default Instrument;
