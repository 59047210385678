import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
    range
} from '../../lib/evo';
import { NoteType, frames } from '../../lib/note';
import { changeInstrumentChannel, changeInstrumentOutput, loadState, removeInstrument } from './actions';

export const qSmall = [NoteType.sixteenth * frames, NoteType.eight * frames];
export const qAll = range(0, frames * 2);

export interface InstrumentEvoParams {
    // Chance that the note can even evolve
    doesEvolve: number; 
    // Chance that tonal quantize is applied (forced into scale notes)
    doesTonalQuantize: number;
    // Chance that tonal quantization is applied when position is changed
    doesTonalQuantizeOnPositionChange: number; 
    // Chance that the note is duplicated
    duplicationChange: number;
    toneChange: number;
    toneChangeSteepness: number;
    toneChangeValuesAbsolute: number[];
    toneMin: number;
    toneMax: number;
    positionChange: number;
    positionChangeSteepness: number;
    positionChangeValuesAbsolute: number[];
    durationChange: number;
    durationChangeSteepness: number;
    durationChangeValuesAbsolute: number[];
    durationMin: number;
    durationMax: number;
    deleteChance: number;
    stretchChange: number;
    stretchChangeSteepness: number;
    stretchChangeValues: number[];
    volumeChange: number;
}

export enum CCCurve {
    None = 'none',
    Linear = 'linear',
}

export interface CCEvent {
    position: number;
    value: number;
}

export interface CCSettings {
    enabled: boolean;
    curve: CCCurve;
    events: CCEvent[];
}

export interface Instrument extends InstrumentEvoParams {
    id: number;
    output: number;
    channel: number;
    cc: CCSettings[];
    name: string;
}

// Refactor this!!!!!!!!!!!! put in state
export let lastId = -1;

export const evoParamsSlice = createSlice({
    name: 'evoParams',
    initialState: {} as Record<number, Instrument>,
    reducers: {
        updateInstrumentParams: function <K extends keyof Instrument>(
            state: Record<number, Instrument>,
            action: PayloadAction<{
                instrument: number;
                key: K;
                value: Instrument[K];
            }>
        ) {
            state[action.payload.instrument][action.payload.key] =
                action.payload.value;
            return state;
        },
        addInstrumentCC: function (
            state: Record<number, Instrument>,
            action: PayloadAction<{
                instrument: number;
                cc: number;
                value: CCEvent;
            }>
        ) {
            const {cc, value} = action.payload
            const instrument = state[action.payload.instrument];

            if (!instrument) {
                return state
            }

            instrument.cc[cc].events.push(value)
            instrument.cc[cc].events = instrument.cc[cc].events.sort((a,b) => a.position - b.position)
            
            return state;
        },
        removeInstrumentCC: function (
            state: Record<number, Instrument>,
            action: PayloadAction<{
                instrument: number;
                cc: number;
                event: number;
            }>
        ) {
            const {cc, event} = action.payload
            const instrument = state[action.payload.instrument];

            if (!instrument) {
                return state
            }

            instrument.cc[cc].events = instrument.cc[cc].events.filter((_, idx) => idx != event)
            
            return state;
        },
        updateInstrumentCC: function (
            state: Record<number, Instrument>,
            action: PayloadAction<{
                instrument: number;
                cc: number;
                event: {enabled: boolean, curve: CCCurve}
            }>
        ) {
            const {cc, event: {enabled, curve}} = action.payload
            const instrument = state[action.payload.instrument];

            if (!instrument) {
                return state
            }

            instrument.cc[cc] = {...instrument.cc[cc], enabled, curve}
            
            return state;
        },
        updateInstrumentCCEvent: function (
            state: Record<number, Instrument>,
            action: PayloadAction<{
                instrument: number;
                cc: number;
                event: number;
                value: CCEvent;
            }>
        ) {
            const {cc, event, value} = action.payload
            const instrument = state[action.payload.instrument];

            if (!instrument) {
                return state
            }

            instrument.cc[cc].events.map((ev, idx) => idx != event ? ev : value)
            
            return state;
        },
        // https://redux-toolkit.js.org/api/createSlice#the-extrareducers-builder-callback-notation
        addInstrument: (state, action: PayloadAction<Instrument>) => {
            lastId++;
            state[lastId] = { ...action.payload, id: lastId };
            return state;
        },
        resetInstruments: (state, action: PayloadAction<Record<number, Instrument>>) => {
            lastId = Object.keys(action.payload)
                .map(x => parseInt(x, 10))
                .reduce((acc, cur) => {
                    console.log(cur, acc)
                    return cur
                }, 0)
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeInstrumentOutput, (state, action) => {
                state[action.payload.instrument].output = action.payload.output;
                return;
            })
            .addCase(changeInstrumentChannel, (state, action) => {
                state[action.payload.instrument].channel = action.payload.channel;
                return;
            })
            .addCase(removeInstrument, (state, action: PayloadAction<{instrument: number}>) => {
                if (state[action.payload.instrument]) {
                    delete state[action.payload.instrument];
                }
                return state;
            })
            .addCase(loadState, (state, action) => {
                return action.payload.instruments
            })
    }
});

// Action creators are generated for each case reducer function
export const { updateInstrumentParams, addInstrument, resetInstruments, updateInstrumentCC, addInstrumentCC, removeInstrumentCC, updateInstrumentCCEvent } =
    evoParamsSlice.actions;

export default evoParamsSlice.reducer;
