import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addHistoryNode as addHistoryNode_, searchTree, setCurrentLeaf } from "../store/reducers/history"
import { RootState } from "../store"
import { getCurrentIteration, resetMelody as resetMelody_ } from "../store/reducers/melody"
import { HistoryNode } from "../lib/history"
// import { resetEvoParams } from "../store/reducers/evoParams"
import { resetSettings } from "../store/reducers/playerConfig"
import { resetZones } from "../store/reducers/zones"
import { resetGlobalEvoParams } from "../store/reducers/globalEvoParams"
import { resetInstruments } from "../store/reducers/instruments"
import { cloneDeep, max, sum } from "lodash"
import { useOutletContext } from "react-router-dom"
import { PlayerRef } from "./Player"

interface NodeProps {
    node: HistoryNode;
    x: number;
    y: number;
    reset: (node: HistoryNode) => void;
    curNode: string;
}

const lineSegmentLength = 30
const scale = 10

const calcWidth = (node: HistoryNode) : number[] => {
    if (!node.children.length) {
        return [1]
    }

    const n = node.children
        .map(n => calcWidth(n))
        .map(x => sum(x))
    
    return n
}

const calcNewx = ({x, lineLen, width, idx, lineSegmentLength}: {x: number, lineLen: number, width: number[], idx: number, lineSegmentLength: number}) => {
    if (!lineLen) {
        return x
    }

    let newX = x + (lineLen / 2 * -1)

    if (width[idx] > 1) {
        newX += (sum(width.slice(0,idx+1)) - 0.5 - (width[idx] / 2)) * lineSegmentLength
    } else {
        newX += (sum(width.slice(0,idx+1)) - 1) * lineSegmentLength
    }

    return newX
}

const Node : FC<NodeProps> = ({node,x,y,reset, curNode}) => {
    let nodes = [
        curNode == node.id ? (
            <circle key={node.id} cx={`${x}px`} cy={`${y}px`} r="2px" stroke="blue" fill="blue"/>
        ) : (
            <circle key={node.id} cx={`${x}px`} cy={`${y}px`} r="2px" stroke="red" fill="red" style={{cursor: 'pointer'}} onClick={() => reset(node)}/>
        )
    ]
    let lines : React.JSX.Element[] = []
    let children : React.JSX.Element[] = []
    const cnt = node.children.length
    const width = calcWidth(node)

    const lineLen = cnt > 1 ? (sum(width) - 1) * lineSegmentLength : 0

    if (cnt > 1) {
        let lineStart = x + (lineLen / 2 * -1)

        if (width[0] > 1) {
            lineStart = x + (lineLen / 2 * -1) + ((width[0] / 2 - 0.5) * lineSegmentLength)
        }

        let lineEnd = x + (lineLen / 2)

        if (width[width.length-1] > 1) {
            lineEnd = x + (lineLen / 2) - ((width[width.length-1] / 2 - 0.5) * lineSegmentLength)
        }

        lines.push(
            <line
                key={node.id + '-line-horizontal'}
                x1={`${lineStart}px`}
                y1={`${y}px`}
                x2={`${lineEnd}px`}
                y2={`${y}px`}
                stroke="black"/>
        )
    }

    children = node.children.map((child, idx) => {
        const diff = child.iteration - node.iteration
        const newX = calcNewx({x, lineLen, width, lineSegmentLength, idx})
        const newY = y + diff * scale

        lines.push(
            <line
                key={child.id+ '-line-vertical'}
                x1={`${newX}px`}
                y1={`${y}px`}
                x2={`${newX}px`}
                y2={`${newY}px`}
                stroke="black"/>
        )

        return <Node curNode={curNode} key={child.id} node={child} x={newX} y={newY} reset={reset}/>
    })

    if (children.length) {
        nodes = [...nodes, ...children]
    }

    return <>{[...lines, ...nodes]}</>
}

const flatten = (tree: HistoryNode) : HistoryNode[] => {
    if (!tree.children) {
        return [tree]
    }

    const result = tree.children.reduce(
        (acc, cur) => {
            return [...acc, ...flatten(cur)]
        },
        [] as HistoryNode[]
    )
    return [tree, ...result]
}

const maxIteration = (node: HistoryNode) : number => {
    return max(flatten(node).map(x => x.iteration))!
}

const History : FC = () => {
    const dispatch = useDispatch()
    const {playerRef} = useOutletContext<{curCount: number, playerRef: React.RefObject<PlayerRef>}>()
    
    // let {tree, currentLeaf} = history
    let {tree, currentLeaf} = useSelector((s: RootState) => s.history)

    const evoParams = useSelector((s: RootState) => s.instruments[1])
    const {current: melody, iteration}  = useSelector((s: RootState) => s.melody)
    const instruments  = useSelector((s: RootState) => s.instruments)
    const zones  = useSelector((s: RootState) => s.zones)
    const globalEvoParams  = useSelector((s: RootState) => s.globalEvoParams)
    const playerConfig = useSelector((s: RootState) => s.playerConfig)
    const [scale, setscale] = useState(5)

    const resetMelody = (node: HistoryNode) => {
        playerRef.current?.stop()
        dispatch(addHistoryNode_({
            id: '',
            instruments,
            zones,
            globalEvoParams,
            melody,
            playerConfig,
            iteration,
            children: [],
        }))

        dispatch(setCurrentLeaf(node))
        dispatch(resetMelody_({melody: node.melody, iteration: node.iteration}))
        dispatch(resetZones(node.zones))
        dispatch(resetGlobalEvoParams({params: node.globalEvoParams}))
        dispatch(resetInstruments(node.instruments))
        dispatch(resetSettings(node.playerConfig)) 
        setTimeout(playerRef.current!.play, 0)

    }

    const addHistoryNode = (e: KeyboardEvent) => {
        if (iteration != tree?.iteration && (
            e.key == " " || e.code == "Space" || e.keyCode == 32
        )) {
            dispatch(addHistoryNode_({
                id: '',
                instruments,
                zones,
                globalEvoParams,
                melody,
                playerConfig,
                iteration,
                children: [],
            }))
        }
        
    }

    useEffect(() => {
        window.addEventListener('keydown', addHistoryNode)

        return () => {
            window.removeEventListener('keydown', addHistoryNode)
        }
    }, [melody, iteration, playerConfig, evoParams])

    

    if (!tree || !currentLeaf) {
        return null
    }

    tree = cloneDeep(tree)
    const lastNode = searchTree(tree, currentLeaf!)!
    let curNodeId = crypto.randomUUID()
    
    lastNode.children = [...lastNode.children, {
        // iteration: lastNode.iteration + 5,
        iteration,
        id: curNodeId,
        melody: [],
        globalEvoParams: lastNode.globalEvoParams,
        instruments: [],
        playerConfig: lastNode.playerConfig,
        zones: [],
        children: []
    }]

    return (
        <div className="svg-layer" style={{position: 'relative'}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="800px"
                height={`${Math.max(600, maxIteration(tree) * scale * 2 + 25)}px`}
                style={{position: 'relative'}}
            >
                <Node node={tree} x={400} y={10} reset={resetMelody} curNode={curNodeId}/>
            </svg>
        </div>
    )
}
export default History