import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { calcMelodyLength, genInstrumentColor } from '../App';
import { numToNote, frames, scaleToString, Note } from '../lib/note';
import { range } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import { Zone } from '../lib/zones';

const octave = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
const range_ = [2, 3, 4, 5, 6, 7, 8, 9]
const black = [1,3,6,,8,10]

interface NotesProps {
    melodyLength: number, 
    countWidth: number, 
    noteHeight: number, 
    curCount: number, 
    melody: Note[]
}

const Notes : FC<NotesProps> = ({melodyLength, countWidth, noteHeight, curCount, melody}) => {
    return (
        <div className="c-melody-vis__notes" style={{
            width: melodyLength * countWidth + 'px',
            transform: `translateX(${(curCount) * (countWidth/frames) * -1}px)`}}
        >
            {range(melodyLength).map((x) => <Count height={noteHeight} width={countWidth}key={x}/>)}
            {melody.map((note, idx) => {
                return <span className="c-melody-vis__note" key={idx} style={{
                    bottom:  note.note * noteHeight - (12 * 2 * noteHeight) + 'px', // compensate for first missing 2 octaves
                    width: countWidth * note.length + 'px',
                    left: countWidth * (note.position / frames) + 'px',
                    height: noteHeight + 'px',
                    backgroundColor: genInstrumentColor(note.instrument)
                }}></span>
            })}
        </div>
    )
}

interface CountProps {
    width: number,
    height: number
}

const Count : FC<CountProps>= ({width, height}) => {
   return (
        <div className="c-melody-vis__count" style={{width: width + 'px'}}>
            {range_.reduce((roll, cur, oIdx) => {
                return [
                    ...roll,
                    ...octave.map((note, idx) => (
                        <span
                            className="c-melody-vis__slot"
                            key={oIdx + ' ' + idx}
                            style={{height: height + 'px'}}
                        >
                        </span>
                    ))
                ];
            }, [] as any[])}
        </div>
    )
}

const MelodyVisualiser: FC = ({}) => {
    const melody = useSelector((s: RootState) => s.melody);
    const melodyLength = calcMelodyLength(melody.current);

    const {curCount, currentZone} = useOutletContext<{curCount: number, currentZone: Zone}>()

    const [noteHeight, setNoteHeight] = useState(10)
    const [countWidth, setCountWidthHeight] = useState(100)
    return (
        <>
        <div style={{ margin: '15px 0' }}>
            <span>Length: {melodyLength} beats</span>
            {
                currentZone ? (
                    <span>; Zone: {numToNote(currentZone.root) + ' ' + scaleToString(currentZone.scale)}</span> 
                ) : null
            }
        </div>


        <div className="c-melody-vis">
            <div className="c-melody-vis__roll">
                {range_.reduce((roll, cur, oIdx) => {
                    return [
                        ...roll,
                        ...octave.map((note, idx) => (
                            <span
                                className={["c-melody-vis__key", black.includes(note) ? 'c-melody-vis__key--black' : ''].filter(Boolean).join(" ")}
                                key={oIdx + ' ' + idx}
                                style={{height: noteHeight + 'px'}}
                            >
                            </span>
                        ))
                    ];
                }, [] as any[]).reverse()}
            </div>
            <div className="c-melody-vis__container">
                <Notes
                    melodyLength={melodyLength}
                    countWidth={countWidth}
                    noteHeight={noteHeight}
                    curCount={curCount}
                    melody={melody.current}
                />
            </div>
        </div>
        </>
        
    );
};

export default MelodyVisualiser;
