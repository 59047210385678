import { range } from "lodash"
import Instrument from "../../components/Instrument"
import { qSmall } from "../../store/reducers/globalEvoParams"
import { RandFunc, passDiceRoll, randMinMax } from "../evo"
import {} from 'webmidi'
import { NoteType, frames } from "../note"
import { MAX_INSTRUMENT_COUNT } from "./consts"
import { CCCurve, CCEvent, CCSettings } from "../../store/reducers/instruments"

const roundPos = (x: number) => {
    return x - (x % frames)
}

const generateCC = (rand: RandFunc, melodyLength: number) => {
    const newCC = {enabled: false, curve: CCCurve.Linear, events: [] as CCEvent[]}

    // newCC.curve = passDiceRoll(0.2, rand) ? CCCurve.None : CCCurve.Linear;

    newCC.events = range(2).map(
        () => ({
            position: roundPos(melodyLength * frames * rand()),
            value: Math.floor(rand() * 127)
        })
    ).sort((a,b) => a.position - b.position)
    
    return newCC
}

const initCC = (rand: RandFunc, melodyLength: number) : CCSettings[] => {
    const cc = range(127).map(() => generateCC(rand, melodyLength))
    
    console.log(cc)
    return cc
}

export const generateInstrument = (rand: RandFunc, melodyLength: number) => {
    const toneMin = randMinMax([15, 96], rand)
    return {
        id: 0,
        output: 0,
        channel: 0,
        name: '',
        cc: initCC(rand, melodyLength),
        doesEvolve: rand(),
        doesTonalQuantize: rand(),
        doesTonalQuantizeOnPositionChange: rand(),
        duplicationChange: rand() * 0.2,
        toneChange: rand(),
        toneChangeSteepness: 3,
        toneChangeValuesAbsolute: range(12, 1),
        toneMin,
        toneMax: randMinMax([toneMin + 6,Math.max(96, toneMin+6)], rand),
        positionChange: rand(),
        positionChangeSteepness: 0,
        positionChangeValuesAbsolute: qSmall,
        stretchChange: 6,
        stretchChangeSteepness: 0,
        stretchChangeValues: [
            NoteType.quarter * frames,
            NoteType.half * frames
        ],
        durationChange: rand(),
        durationChangeSteepness: 2,
        durationChangeValuesAbsolute: [
            NoteType.sixteenth,
            NoteType.eight,
            NoteType.quarter,
            NoteType.eight + NoteType.quarter
        ],
        durationMax: NoteType.whole + NoteType.half,
        durationMin: NoteType.thirtysecond,
        deleteChance: rand() * 0.2,
        volumeChange: rand()
    }
}

interface GenerateInstrumentsProps {
    rand: RandFunc,
    melodyLength: number
}

export const generateInstruments = ({rand, melodyLength}: GenerateInstrumentsProps) => {
    const n = Math.floor(rand() * MAX_INSTRUMENT_COUNT) + 1
    const instruments : Instrument[] = []
    for (let i = 0; i < n; i++) {
        const newI = generateInstrument(rand, melodyLength)

        newI.id = i
        newI.name = i + 1 + ''
        newI.output = 0
        newI.channel = i + 1

        instruments.push(newI)
    }

    return instruments
}