import { createSlice } from '@reduxjs/toolkit'
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { HistoryNode } from '../../lib/history';
import { RootState } from '..';
import { cloneDeep } from 'lodash';
import { loadState } from './actions';

type HistoryState = {
    tree?: HistoryNode;
    currentLeaf?: string;
}

const initialState : HistoryState = {}

export const searchTree = (tree: HistoryNode, id: string) : HistoryNode | undefined => {
    if (tree.id === id) {
        return tree
    }

    for (let idx = 0; idx < tree.children.length; idx++) {
        const child = tree.children[idx];

        const foundNode = searchTree(child, id)

        if (foundNode) {
            return foundNode
        }
    }

    return undefined
}

export const evoParamsSlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    addHistoryNode: (state, action: PayloadAction<HistoryNode>) => {
        const node = action.payload
        node.id = crypto.randomUUID()

        if (!state.tree) {
            return {
                tree: node,
                currentLeaf: node.id
            }
        }

        const {tree, currentLeaf} = state

        const parent = searchTree(tree, currentLeaf!)
        parent?.children.push(node)

        state.tree = tree
        state.currentLeaf = node.id
        
        return state
    },
    resetHistory: (state, action: PayloadAction) => {
        return {}
    },
    setCurrentLeaf: (state, action: PayloadAction<HistoryNode>) => {
        if (!state) {
            return {
                tree: action.payload,
                currentLeaf: action.payload.id
            }
        }

        return {tree: state.tree, currentLeaf: action.payload.id}
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(loadState, (state, action) => {
            return action.payload.history
        })
}
})

// Action creators are generated for each case reducer function
export const { addHistoryNode, setCurrentLeaf, resetHistory } = evoParamsSlice.actions

export const getHistoryTree = ({history}: RootState) => cloneDeep(history.tree)

export const getCurrentLeaf = ({history}: RootState) => {
    if (!history.tree || !history.currentLeaf) {
        return null
    }

    return searchTree(history.tree, history.currentLeaf)
}

export default evoParamsSlice.reducer

