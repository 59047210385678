import {
    GlobalEvoParams,
    RandFunc,
    randFromArray,
    randMinMax,
    passDiceRoll
} from '../evo';
import { scales, frames } from '../note';
import { Zone, needsNewZone, genNewZone } from '../zones';

interface GenerateZonesProps {
    length: number;
    globalEvoParams: GlobalEvoParams;
    rand: RandFunc;
}

export const generateZones = ({
    length,
    globalEvoParams,
    rand
}: GenerateZonesProps) => {
    let zones: Zone[] = [
        {
            start: 0,
            scale: scales[
                randFromArray(Object.keys(scales), rand) as keyof typeof scales
            ],
            root: randMinMax([0, 12], rand)
        }
    ];

    for (let i = 0; i < length; i++) {
        if (
            needsNewZone(zones, i * frames, globalEvoParams) &&
            passDiceRoll(globalEvoParams.addZoneChance, rand)
        ) {
            zones = [
                ...zones,
                genNewZone(zones, i * frames, globalEvoParams, rand)
            ];
        }
    }

    return zones;
};
