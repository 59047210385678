import { FC, useRef, useState, useEffect } from 'react';
import { Note, NoteType, frames } from './lib/note';
import { evoMelody } from './lib/evo';
import Player, { PlayerRef } from './components/Player';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from './store';
import { updateSettings } from './store/reducers/playerConfig';
import { resetMelody } from './store/reducers/melody';
import { formatDate } from 'date-fns/format';
import {
    Button,
    ButtonGroup, Chip, IconButton, Stack,
    Typography
} from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Loop from '@mui/icons-material/Loop';
import { matchPath } from 'react-router';
import generateSettings from './lib/dna';
import { resetGlobalEvoParams } from './store/reducers/globalEvoParams';
import { Instrument, resetInstruments } from './store/reducers/instruments';
import { resetZones } from './store/reducers/zones';
import { Zone } from './lib/zones';
import SeedModal from './components/SeedModal';
import MersenneTwister from './lib/random';
import { addHistoryNode, resetHistory } from './store/reducers/history';
import { updateAppSettings } from './store/reducers/app';
import LoadModal from './components/LoadModal';
import { loadState } from './store/reducers/actions';

export const calcMelodyLength = (melody: Note[]) => {
    if (!melody.length) {
        return 1;
    }
    const latestNote = melody[melody.length - 1].position;
    let loopRange_ = Math.ceil(latestNote / (NoteType.quarter * frames));
    if (latestNote % (NoteType.quarter * frames) === 0) {
        loopRange_ += 1;
    }

    return loopRange_;
};

export const genInstrumentColor = (n: number) => {
    const rand = new MersenneTwister(n+354)
    return `rgb(${Math.floor(rand.random() * 255)},${Math.floor(rand.random() * 255)},${Math.floor(rand.random() * 255)})`
}


const save = () => {
    const state = store.getState()
    const aElement = document.createElement('a');
    aElement.setAttribute('download', `midevol-save-${state.app.seed}-${formatDate(new Date(), 'yyyy-MM-dd-hhmmss')}.json`);
    const href = URL.createObjectURL(new Blob([JSON.stringify(state)], {type: "application/json"}));
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
}

const App: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loadModalOpen, setLoadModalOpen] = useState(false)

    const playerConfig = useSelector((s: RootState) => s.playerConfig);
    const playerRef = useRef<PlayerRef>(null);
    const [curCount, setCurCount] = useState(1);
    const [trigger, setTrigger] = useState(0);
    const [triggerLoopChange, setTriggerLoopChange] = useState(0);
    
    const {current: melody} = useSelector((s: RootState) => s.melody);
    const instruments = useSelector((s: RootState) => s.instruments);
    const globalEvoParams = useSelector((s: RootState) => s.globalEvoParams);
    
    const zones = useSelector((s: RootState) => s.zones);
    const [currentZone, setCurrentZone] = useState<Zone | undefined>(zones[0]);

    const seed = useSelector((s: RootState) => s.app.seed)
    const setSeed = (seed: string) => {
        dispatch(updateAppSettings({key: 'seed', value: seed}));
        playerRef.current?.isPlaying && playerRef.current!.stop();

        if (!seed) {
            return;
        }

        (async () => {
            const initialSetings = await generateSettings(seed);

            dispatch(
                resetGlobalEvoParams({ params: initialSetings.globalEvoParams })
            );
            dispatch(
                resetInstruments(
                    initialSetings.instruments.reduce((acc, cur) => {
                        acc[cur.id] = cur;
                        return acc;
                    }, {} as Record<number, Instrument>)
                )
            );
            dispatch(
                resetMelody({ melody: initialSetings.melody, iteration: 0 })
            );
            dispatch(resetZones(initialSetings.zones));
            setCurrentZone(initialSetings.zones[0]);
            
            dispatch(resetHistory())
            dispatch(
                addHistoryNode({
                    id: '',
                    iteration: 0,
                    melody: initialSetings.melody,
                    globalEvoParams: initialSetings.globalEvoParams,
                    instruments: initialSetings.instruments,
                    playerConfig,
                    zones: initialSetings.zones,
                    children: []
                })
            )
        })();
    }

    const cb = useRef(
        evoMelody({
            setZones: (zones) => dispatch(resetZones(zones)),
            melody,
            zones,
            globalEvoParams,
            dispatch,
            instruments,
            rand: () => Math.random()
        })
    );

    useEffect(() => {
        cb.current = evoMelody({
            setZones: (zones) => dispatch(resetZones(zones)),
            melody,
            zones,
            globalEvoParams,
            dispatch,
            instruments,
            rand: () => Math.random()
        });
    }, [melody, zones, globalEvoParams, instruments]);

    useEffect(() => {
        if (!triggerLoopChange) {
            return;
        }
        cb.current();
    }, [triggerLoopChange]);

    return (
        <div>
            <Stack className='c-nav' alignItems="center" marginBottom={1} gap={2} direction="row">
                <Player
                    ref={playerRef}
                    beforeLoop={() => setTriggerLoopChange((i) => i + 1)}
                    zones={zones}
                    onZoneChange={setCurrentZone}
                    updateCount={setCurCount}
                />
                <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                >
                    <IconButton
                        color={
                            playerRef.current?.isPlaying() && !playerConfig.loop
                                ? 'primary'
                                : 'default'
                        }
                        onClick={() => {
                            dispatch(
                                updateSettings({ key: 'loop', value: false })
                            );
                            setTrigger((x) => x + 1);
                            playerRef.current?.stop();
                            playerRef.current?.play();
                        }}
                    >
                        <PlayArrow />
                    </IconButton>
                    <IconButton
                        color={
                            playerRef.current?.isPlaying() && playerConfig.loop
                                ? 'primary'
                                : 'default'
                        }
                        onClick={() => {
                            dispatch(
                                updateSettings({ key: 'loop', value: true })
                            );
                            setTrigger((x) => x + 1);
                            playerRef.current?.stop();
                            playerRef.current?.play();
                        }}
                    >
                        <Loop />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setTrigger((x) => x + 1);
                            playerRef.current?.stop();
                        }}
                    >
                        <StopIcon />
                    </IconButton>
                </ButtonGroup>
                <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                >
                    <Button
                        color={
                            matchPath(location.pathname, '/')
                                ? 'primary'
                                : 'inherit'
                        }
                        onClick={() => navigate('/')}
                    >
                        Instruments
                    </Button>
                    <Button
                        color={
                            matchPath(location.pathname, '/visual')
                                ? 'primary'
                                : 'inherit'
                        }
                        onClick={() => navigate('/visual')}
                    >
                        Visual
                    </Button>
                    <Button
                        color={
                            matchPath(location.pathname, '/history')
                                ? 'primary'
                                : 'inherit'
                        }
                        onClick={() => navigate('/history')}
                    >
                        History
                    </Button>
                    <Button
                        color={
                            matchPath(location.pathname, '/config')
                                ? 'primary'
                                : 'inherit'
                        }
                        onClick={() => navigate('/config')}
                    >
                        Config
                    </Button>
                </ButtonGroup>
               
                <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                >
                    <Button
                        color='inherit'
                        onClick={save}
                    >
                        Save
                    </Button>
                    <Button
                        color='inherit'
                        onClick={() => setLoadModalOpen(true)}
                    >
                        Load
                    </Button>
                </ButtonGroup>
                <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                >
                    <Button
                        color='inherit'
                        onClick={() => setSeed('')}
                    >
                        Provide new DNA
                    </Button>
                </ButtonGroup>

                {seed ? ( <Stack alignItems="center" gap={2} direction="row">
                    <Typography>Current seed: </Typography><Chip label={seed}/>
                </Stack>) : null}
            </Stack>

            <SeedModal seed={seed} setNewSeed={setSeed} />
            {
                loadModalOpen ? <LoadModal
                    setOpen={setLoadModalOpen}
                    loadState={(s) => playerRef.current?.stop() || dispatch(loadState(s))}
                /> : null
            }
            <Outlet context={{curCount, currentZone, playerRef}}/>
        </div>
    );
};

export default App;

