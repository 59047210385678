import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { loadState } from './actions';

export interface PlayerConfigState {
    loop: boolean; //true)
    instantantQuantizeScale: boolean; //true)
    metronome: boolean; //true)
    drumsOutput: number; //1)
    bpm: number;
}

export const initialState: PlayerConfigState = {
    loop: true,
    instantantQuantizeScale: true,
    metronome: true,
    drumsOutput: 1,
    bpm: 120,
}

export const playerConfigSlice = createSlice({
  name: 'playerConfig',
  initialState,
  reducers: {
    updateSettings: function <K extends keyof PlayerConfigState>(
        state: PlayerConfigState,
        action: PayloadAction<{key: K; value: PlayerConfigState[K]}>,
    ) {
        state[action.payload.key] = action.payload.value;

        return state;
    },
    resetSettings: (state, event: PayloadAction<PlayerConfigState>) => {
      return event.payload
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadState, (state, action) => {
          return action.payload.playerConfig
      })
  }
})

// Action creators are generated for each case reducer function
export const { updateSettings, resetSettings } = playerConfigSlice.actions

export default playerConfigSlice.reducer

