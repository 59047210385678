import { Dispatch } from 'react';
import { Instrument, InstrumentEvoParams } from '../store/reducers/instruments';
import melody, { updateMelody } from '../store/reducers/melody';
import { Note, NoteType, frames } from './note';
import { Zone, genNewZone, getZone, needsNewZone } from './zones';
import { calcMelodyLength } from '../App';

// export const genEffectiveScale = ({scale: scaleKey, effectiveScalePercentage}: EvoParams) => {
//     let effectiveScale = [];
      
//     let scale = [...scales[scaleKey]];
//     const noteCount = Math.max(1, Math.round(scale.length * effectiveScalePercentage))
    
//     let idx = 0;
  
//     while (effectiveScale.length < noteCount) {
      
  
//       if (passDiceRoll(0.01)) {
//         effectiveScale.push(scale[idx])
//         scale = scale.filter((_, i) => i != idx)
//       }
//       idx++
//       if (scale.length <= idx) {
//         idx = 0
//       }
//     }
  
//     effectiveScale = effectiveScale.sort()
//     console.log('effectiveScale', effectiveScale)
//     return effectiveScale
// }

export const scaleQuantize = (note: number, scale: number[], key: number, doesTonalQuantize: number, rand: RandFunc) => {
    if (!passDiceRoll(doesTonalQuantize, rand)) {
        return note
    }

    let found = false;
    let target = note % 12;
    // console.log('org', key, scale.map(n => [n, numToNote(n)]))
    scale = scale.map((n) => (n + key) % 12);
    // console.log('org mod', key, scale.map(n => [n, numToNote(n)]))
    scale = scale.sort((a, b) => a - b);
    scale.push(scale[scale.length - 1] + 12);
    // console.log('org mod sort', key, scale.map(n => [n, numToNote(n)]))
    let idx = scale.indexOf(target);

    if (idx != -1) {
        return note;
    }

    idx = 0;

    while (!found) {
        const cur = scale[idx];

        if (idx > scale.length) {
            // console.log(
            //     `${note}, ${numToNote(note)} => ${cur}, ${numToNote(
            //         cur
            //     )} => ${note}, ${numToNote(note)}`
            // );
            return note;
        }
        if (cur > target) {
            const last = scale[idx - 1];
            const diffA = Math.abs(last - target);
            const diffB = Math.abs(cur - target);
            if (diffA === diffB) {
                const ret = note + (rand() > 0.5 ? diffA * -1 : diffB);
                // console.log(
                //     `${note}, ${numToNote(note)} => ${ret}, ${numToNote(ret)}`
                // );
                return ret;
            }

            const ret = note + (diffA < diffB ? diffA * -1 : diffB);
            // console.log(
            //     `${note}, ${numToNote(note)} => ${ret}, ${numToNote(ret)}`
            // );
            return ret;
        }

        if (cur == target) {
            console.log('same', cur, target, note);
            return note;
        }
        idx++;
    }
};

type Range = [number, number];
type Ramp = number[];

export const quantizePosition = (position: number, notes: number[]) : number => {
    const minDiff = notes.reduce((min, cur) => {
        // 75; [80, 65]
        const diffA = (position % cur) * -1 // -75, -10
        const diffB = cur - (position % cur) // 5, 55

        const diff = Math.abs(diffA) < Math.abs(diffB) ? diffA : diffB

        if (min === Infinity) {
            return diff
        }

        if (Math.abs(diff) < Math.abs(min)) {
            return diff
        }

        return min
    }, Infinity)

    return position + minDiff
}

export interface GlobalEvoParams {
    minDistance: number, // Min time to last zone change before a new change can happen
    addZoneChance: number // Chance that a zone is added when a change can happen
    maxZoneTonalChange: number // Max number of notes than can change on zone a change change
}

export type RandFunc = () => number

export function passDiceRoll(percentage: number, rand: RandFunc): boolean {
    return rand()< percentage;
}

export function randMinMax(params: Range, rand: RandFunc) {
    const min = params[0];
    const max = params[1];
    const diff = max - min;
    return Math.round(rand() * diff + min);
}

export function randFromArray<T>(arr: T[], rand: RandFunc) : T {
    return arr[Math.floor(rand()*arr.length)]
}

function randInvert(n: number, rand: RandFunc): number {
    return rand() < 0.5 ? n : n * -1;
}

export function execRamp(values: number[], ramp: Ramp, rand: RandFunc): number {
    const diceRoll = rand();
    let acc = 0;
    for (let idx = 0; idx < ramp.length; idx++) {
        const element = ramp[idx];
        acc += element;
        if (diceRoll < acc) {
            return values[idx];
        }
    }

    return 0;
}

export const range = (n: number, min = 1) => {
    let ret = [];

    for (let idx = 1; idx < min + n; idx++) {
        ret.push(idx);
    }

    return ret;
};

const calcRamp = (values: number[], steepness: number) => {
    const x = values.map((_, idx) => Math.pow(idx + 1, steepness)).reverse();
    const c = 1 / x.reduce((a, c) => a + c);

    return x.map((y) => y * c);
};

export const execWithRamp = (values: number[], steepness: number, rand: RandFunc) => {
    return execRamp(values, calcRamp(values, steepness), rand);
};

function clamp(n: number, [min, max]: Range) {
    return Math.max(Math.min(n, max), min);
}

export function evoNote(
    note: Note,
    isLast: boolean,
    evoParams: InstrumentEvoParams,
    zones: Zone[],
    rand: RandFunc
): Note[] {
    const ret = [];

    const n: Note = { ...note };

    if (!passDiceRoll(evoParams.doesEvolve, rand)) {
        return [n]
    }

    const zone = getZone(n, zones)

    if (passDiceRoll(evoParams.positionChange, rand)) {
        const add = randInvert(
            execWithRamp(
                evoParams.positionChangeValuesAbsolute,
                evoParams.positionChangeSteepness,
                rand
            ),
            rand
        );

        n.position += add

        n.note = scaleQuantize(
            clamp(n.note, [evoParams.toneMin, evoParams.toneMax]),
            zone.scale,
            zone.root,
            evoParams.doesTonalQuantizeOnPositionChange,
            rand
        )!;
    }

    if (passDiceRoll(evoParams.toneChange, rand)) {
        n.note += randInvert(
            execWithRamp(
                evoParams.toneChangeValuesAbsolute,
                evoParams.toneChangeSteepness,
                rand
            ),
            rand
        );

        n.note = scaleQuantize(
            clamp(n.note, [evoParams.toneMin, evoParams.toneMax]),
            zone.scale,
            zone.root,
            evoParams.doesTonalQuantize,
            rand
        )!;
    }

    if (passDiceRoll(evoParams.durationChange, rand)) {
        n.length += randInvert(
            execWithRamp(
                evoParams.durationChangeValuesAbsolute,
                evoParams.durationChangeSteepness,
                rand
            ),
            rand
        );
        n.length = clamp(n.length, [
            evoParams.durationMin,
            evoParams.durationMax
        ]);
    }

    if (passDiceRoll(evoParams.volumeChange, rand)) {
        n.volume = (n.volume || 1) + randInvert(rand() * 0.3, rand);
        n.volume = clamp(n.volume, [0.1, 1]);
    }

    if (
        n.position >= 0 &&
        (rand() < 1 - evoParams.deleteChance || isLast)
    ) {
        ret.push(n);
    }

    if (passDiceRoll(evoParams.duplicationChange, rand)) {
        ret.push({ ...note });
    }

    return ret;
}

export interface EvoCCProps {
    dispatch: Dispatch<any>,
    rand: RandFunc,
    instruments: Record<number, Instrument>,
    melody: Note[],
}

export const evoCC = ({dispatch, rand, instruments, melody}: EvoCCProps) => {
    const length = calcMelodyLength(melody)
    Object.keys(instruments).forEach((k) => {
        const instrument = instruments[parseInt(k, 10)]

        // TODO evo stuff
    })
} 

export interface EvoMelodyProps {
    melody: Note[],
    instruments: Record<number, Instrument>,
    zones: Zone[],
    globalEvoParams: GlobalEvoParams,
    setZones: (zone: Zone[]) => void,
    dispatch: Dispatch<any>,
    rand: RandFunc
}

export const evoMelody = ({melody, instruments, zones, globalEvoParams, dispatch, setZones, rand} :EvoMelodyProps) => () => {
    if (!melody.length) {
        return melody
    }

    let newMelody = melody.reduce((acc, curNote, idx) => {
        const evoParams = instruments[curNote.instrument]

        return [
            ...acc,
            ...evoNote(
                curNote,
                melody.length < 2 || (idx > 0 && acc.length < 2),
                evoParams,
                zones,
                rand
            )
        ];
    }, [] as Note[]);

    // newMelody = playerConfig.instantantQuantizeScale
    //     ? newMelody.map((x) => {
    //         const evoParams = allEvoParams[x.instrument]
    //         return {
    //             ...x,
    //             note: scaleQuantize(
    //                 x.note,
    //                 evoParams.effectiveScale,
    //                 evoParams.key
    //             )!
    //         }
    //     })
    //     : newMelody;
    
    newMelody = newMelody.sort((a, b) => a.position - b.position);
    let latestNote = newMelody[newMelody.length - 1].position;

    const noteDensity = newMelody.length / (latestNote / 60)
    Object.keys(instruments).forEach((id) => {
        const evoParams = instruments[parseInt(id)]

        if (noteDensity > evoParams.stretchChange) {
            const opschuiven = execWithRamp(
                evoParams.stretchChangeValues,
                evoParams.stretchChangeSteepness,
                rand
            )
            newMelody = newMelody.map(n  => {
                if (n.instrument == parseInt(id)) {
                    // Dont change if note start on 0
                    // If pos or latest is 0 new pos results in NaN || Infinity
                    if (!n.position || !latestNote) {
                        return n
                    }

                    return {...n, position: 
                        quantizePosition(
                            Math.round(n.position + ((n.position / latestNote) * opschuiven)),
                            evoParams.positionChangeValuesAbsolute
                        )
                    }
                }

                return n
            })
        }
    })

    latestNote = newMelody[newMelody.length - 1].position;
    let loopRange_ = Math.ceil(latestNote / (NoteType.quarter * frames));
    if (latestNote % (NoteType.quarter * frames) === 0) {
        loopRange_ += 1;
    }


    // Add new zone?
    if (needsNewZone(zones, melody[melody.length-1].position, globalEvoParams) && passDiceRoll(globalEvoParams.addZoneChance, rand)) {
        const newZones = [...zones, genNewZone(zones, melody[melody.length-1].position, globalEvoParams, rand)]
        setTimeout(() => setZones(newZones), 0)
    }

    setTimeout(() => dispatch(updateMelody([newMelody, true])), 0)
    evoCC({dispatch, instruments, rand, melody})
};
