import { Instrument } from "../../store/reducers/instruments";
import { MAX_MELODY_LENGTH } from "./consts";
import { GlobalEvoParams } from "../evo";
import { generateInstruments } from "./instruments";
import { generateMelody, sortMelody } from "./melody";
import { Note, frames } from "../note";
import MersenneTwister from "../random";
import { generateZones } from "./zones";
import { Zone } from "../zones";

export * from "./zones"
export * from "./melody"
export * from "./instruments"

export async function sha256(str: string) {
    const buffer = new TextEncoder().encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray
}

export interface DNA {
    zones: Zone[],
    melody: Note[],
    globalEvoParams: GlobalEvoParams,
    instruments: Instrument[]
}

const initialGlobalParams = {
    minDistance: frames * 16,
    addZoneChance: 1, // 100% for now,
    maxZoneTonalChange: 1
}

const generateSettings = async (seed: string) : Promise<DNA> => {
    const rand = new MersenneTwister(await sha256(seed))
    const randFunc = () => rand.random()
    const melodyLength = Math.ceil(randFunc() * MAX_MELODY_LENGTH / 4) * 4

    const globalEvoParams = {...initialGlobalParams}
    
    const zones = generateZones({length: melodyLength, rand: randFunc, globalEvoParams})
    const instruments = generateInstruments({rand:randFunc, melodyLength})

    let melody = instruments.reduce((acc, instrument) => {
        acc = [...acc, ...generateMelody({
            density: randFunc(),
            length:  melodyLength,
            rand: randFunc,
            zones,
            instrument
        })]
        return acc
    }, [] as Note[])

    melody = sortMelody(melody)
    
    return {
        zones,
        melody,
        instruments,
        globalEvoParams,
    }
}

export default generateSettings